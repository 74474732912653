<template>
  <div class="user-service-list">
    <location-bar v-if="$store.getters['app/windowSize'] <= 767" />
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <div class="search-results">
                {{ $t('Showing') }} {{ filterStoreList.length }} {{ $t('of') }} {{ serviceList.length }} {{ $t('results') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchQuery"
              placeholder="Search..."
              class="search-product"
              @keyup="beginSearch"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <row-box-skeleton
      v-if="!dataLoaded"
      :show-top-line="false"
      class="mt-1"
    />
    <!-- Prodcuts -->

    <div
      v-if="dataLoaded && serviceList.length === 0"
      class="mx-auto"
    >
      <img
        class="no-store-found-image"
        :src="require('@/assets/images/app-images/NoOptionAvailable.svg')"
      >
      <p class="text-center mt-3">
        {{ $t('Sorry, Currently no options are available for your selection') }}
      </p>
    </div>

    <section
      v-if="dataLoaded && serviceList.length > 0"
      :class="itemView"
    >
      <b-card
        v-for="product in filterStoreList"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <figure
            @mouseenter="$superApp.helper.zoomMagic"
            @mouseout="$superApp.helper.zoomOut"
          >
            <v-zoomer
              :zoomed.sync="$superApp.zoomed"
              :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom"
            >
              <b-img
                :alt="`${product.service_subcategory_name}-${product.id}`"
                fluid
                class="card-img-top"
                :src="$helpers.imageHelper(product.picture)"
                @error="$helpers.imageError"
              />
            </v-zoomer>
          </figure>
        </div>

        <div :class="{'d-flex align-items-start flex-column': itemView === 'list-view'}">
          <!-- Product Details -->
          <b-card-body :class="{'mb-auto': itemView === 'list-view'}">
            <h6 class="item-name">
              <b-link
                class="text-body"
                :to="{ path: getServiceUrl(product) }"
              >
                {{ product.service_subcategory_name }}
              </b-link>
            </h6>
          </b-card-body>

          <div class="item-options text-center">
            <b-button
              v-if="!isThirdParty(product)"
              :to="{ path: getServiceUrl(product) }"
              variant="primary"
              tag="a"
              class="btn-cart"
            >
              <span>{{ $t('View Service') }}</span>
            </b-button>
            <b-button
              v-if="isThirdParty(product)"
              variant="primary"
              class="btn-cart"
              @click="goToThirdParty(product)"
            >
              <span>{{ $t('View Service') }}</span>
            </b-button>
          </div>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormRadio, BRow, BInputGroup, BFormInput, BInputGroupAppend, BCol, BCard, BLink, BCardBody, BButton, BImg } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import RowBoxSkeleton from '@/@core/layouts/shared/RowBoxSkeleton.vue'
import eventBus from '@/libs/event-bus'
import LocationBar from '@/layouts/components/LocationBar.vue'
import { useUserUi } from './useUser'

export default {
  components: {
    LocationBar,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCol,
    BCard,
    BLink,
    BCardBody,
    BButton,
    BImg,
    RowBoxSkeleton,
  },
  data() {
    return {
      serviceList: [],
      filterStoreList: [],
      itemView: 'grid-view grid-view-4',
      searchQuery: '',
      dataLoaded: false,
    }
  },
  mounted() {
    this.getServiceList()
    this.resizeOccured()
    window.addEventListener('resize', this.resizeOccured)
    eventBus.$on('new-address-selected', payload => {
      let defaultLatLong = ''
      if (payload && payload.latitude && payload.longitude) {
        defaultLatLong = `latitude=${payload.latitude}&longitude=${payload.longitude}`
      }
      this.getServiceList(defaultLatLong)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeOccured)
    eventBus.$off('new-address-selected')
  },
  methods: {
    isThirdParty(product) {
      return product.third_party && product.third_party_url
    },
    goToThirdParty(product) {
      if (product.third_party === 1) {
        this.$swal({
          title: this.$t('You will be redirected to a third party website'),
          iconHtml: this.$helpers.swalIcon('alert.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            setTimeout(() => {
              window.open(product.third_party_url, '_BLANK')
            }, 100)
          }
        })
      }
    },
    getServiceList(defaultLatLong = '') {
      const { userServiceSubCategory } = useUserUi()
      const { serviceName } = this.$route.params
      const payload = []
      if (window.SuperApp.getters.userCity()) {
        payload.push(`city_id=${window.SuperApp.getters.userCity()}`)
      }
      if (defaultLatLong && typeof defaultLatLong === 'string') {
        payload.push(defaultLatLong)
      }
      showLoader()
      userServiceSubCategory(`${serviceName}${payload.length > 0 ? `?${payload.join('&')}` : ''}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceList = data.responseData
            this.beginSearch()
          } else {
            showDangerNotification(this, 'Failed to get service list')
          }
          this.dataLoaded = true
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
          this.dataLoaded = true
        })
    },
    resizeOccured() {
      if (window.innerWidth <= 767) {
        this.itemView = 'list-view'
      }else{
        this.itemView = 'grid-view grid-view-4'
      }
    },
    beginSearch() {
      if (this.searchQuery) {
        this.filterStoreList = this.serviceList.filter(x => x.service_subcategory_name && x.service_subcategory_name.toLowerCase().includes(this.searchQuery.toLowerCase()))
      } else {
        this.filterStoreList = this.serviceList
      }
    },
    getServiceUrl(product) {
      return `/user/services/providers/${this.$route.params.serviceName}/${product.slug}`
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.ecommerce-application .grid-view .ecommerce-card .item-img {
  justify-content: center;
  padding-top: 0;
}

.user-service-list .grid-view .ecommerce-card img {
  height: 250px;
  width: 100%;
  background-position: center;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .ecommerce-application .list-view .ecommerce-card .item-img {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ecommerce-application .list-view .ecommerce-card .item-options .btn-cart {
    margin-top: 0;
  }

  .ecommerce-application .list-view .ecommerce-card .card-body {
    padding-bottom: 0;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .user-service-list {
    .grid-view .ecommerce-card .item-img {
      min-height: 10.85rem;

      img {
        height: 150px;
      }
    }

    .list-view .ecommerce-card {
      grid-template-columns: 1fr 1fr;

      .item-img a {
        height: 100%;

        img {
          height: 100%;
        }
      }

      .item-options .btn-cart {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
